import React, { useContext } from 'react';
import { TiArrowRightOutline } from 'react-icons/ti';
import { GlobalDataContext } from '../../context/context';
import imgDefault from '../../assets/image/placeholder.png'


const Block = ({ title, text, listsAbout, listsServices, image1, sloganPrincipal }) => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <div className='bg-[#efefef] py-[90px]'>
            <div className='w-[95%] md:w-[90%] mx-auto justify-center'>
                <div className='w-[80%] md:w-[90%] h-auto flex-col md:flex md:flex-row justify-center items-center space-x-0 md:space-x-6 mx-auto'>
                    
                    
                    <div className='md:w-[40%] w-full h-[650px] rounded-none md:rounded-3xl rounded-t-2xl bg-white px-10 py-36 flex flex-wrap items-center my-auto'>
                        {
                            sloganPrincipal ?
                                rpdata?.dbPrincipal?.licensed ?
                                    <h2 className='pb-3 capitalize'>
                                        {rpdata?.dbPrincipal?.licensed}
                                    </h2>
                                    : <h2 className='pb-10 capitalize'>
                                        {
                                            rpdata?.dbPrincipal?.exprYears ?
                                                `we have ${rpdata?.dbPrincipal?.exprYears} years of experience`
                                                : 'we have many years of experience'
                                        }
                                    </h2>
                                :
                                <h2 className='pb-3 capitalize'>
                                    {
                                        title ? title :
                                            <span className='lowercase text-[18px]'>
                                                {`default title={'frase'}`} o
                                                {` agregar licencia o años de experienceias sloganPrincipal={true}`}
                                            </span>
                                    }
                                </h2>
                        }
                        <p className='pb-3'>
                            {
                                text ? text :
                                    <span>{`para agregar el texto -> text={'description'}`}
                                        <br />{'para agregar lista de about -> listsAbout={true}'}
                                        <br />{'para agregar lista de servicios -> listsServices={true}'}
                                    </span>
                            }
                        </p>
                    </div>
                    <div className='md:w-[60%] w-full md:h-[650px] h-full flex flex-col-reverse  md:flex md:flex-row justify-start rounded-none md:rounded-3xl rounded-b-2xl bg-white p-10 px-10 py-10 md:py-20'>
                        <div className='md:w-[50%] w-full h-full pt-4 p-0 md:p-10 flex justify-start items-center'>
                            {
                                listsAbout ?
                                    <ul className='grid grid-cols-1 md:grid-cols-1 pb-5 gap-y-2'>
                                        {
                                            rpdata?.dbAbout?.[0].list.length > 1 ?
                                                rpdata?.dbAbout?.[0].list.map((item, index) => {
                                                    return (

                                                        <li key={index} className="py-2 flex items-center">
                                                            <TiArrowRightOutline/>
                                                            <span className="pl-2">{item}</span>
                                                        </li>
                                                    )
                                                })
                                                : null
                                        }
                                    </ul>
                                    : null
                            }
                            {
                                listsServices ?
                                    <ul className="grid grid-cols-1 md:grid-cols-1 pb-5 gap-y-4">
                                        {rpdata?.dbServices?.slice(0, 6).map((item, index) => {
                                            return (
                                                <li key={index} className="py-1 flex items-center">
                                                    <TiArrowRightOutline />
                                                    <span>{item.name}</span>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    : null
                            }
                        </div>
                        <div className='md:w-[50%] w-full h-[350px] md:h-full bg-center bg-cover rounded-2xl' style={{ backgroundImage: `url("${image1 ? image1 : imgDefault}")` }}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Block;